import React, { useState } from "react";
import Button from "../../ui/elements/Button";
import { Icon } from "../../ui";
import { accordionFullData, journeyData, whyChooseData } from "./mock";
import { NavLink, useNavigate } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import axios from "axios";

const Home = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    try {
      const response = await axios.post(
        "https://api.zippyglide.com/api/orders/get-by-id",
        {
          id: inputValue,
          site_url:"zippyglide.com"
        }
      );
      if (response.data) {
        navigate("/detail", { state: { data: response.data } });
      } else {
        setShowMessage(true);
        setInputValue("");
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setInputValue("");
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* NAVBAR */}

      <section id="home">
        <div className="hero-sec position-relative">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 text-center my-2 scale-up-center">
                <h1>
                  Track Your <span>Courier Seamlessly </span> with Zippyglide
                </h1>
                <p>
                  Zippyglide offers real-time tracking for your shipments,
                  providing instant updates and peace of mind, so you always
                  know where your package is, from dispatch to delivery.
                </p>

                <div className="mybtn">
                  <input
                    type="text"
                    placeholder="Track Shipment"
                    className="form-control"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  <Button
                    className="primarybtn"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {loading ? (
                      <div className="loader"></div>
                    ) : (
                      <IoIosSearch size={26} />
                    )}
                  </Button>
                </div>
                {showMessage && (
                  <div className="message pt-1">Order Details not found!</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="darkbg">
          <div className="container">
            <div className="get_app">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-4 col-md-8">
                  <h4>Get the App Now!</h4>
                  <p>
                    Download the Zippyglide app now for a seamless fantasy
                    cricket experience. Join leagues, create teams, and win big!
                  </p>
                </div>
                <div className="col-lg-4 col-md-4 text-md-end text-sm-start">
                  <a
                    type="button"
                    className="whitebtn"
                    href={apk}
                    download="funzone.apk"
                  >
                    Download App Free
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="start_withmain comman_space">
        <div className="container">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  We Follow <span>Some Steps To Deliver</span> Your Courier
                </h4>
                <p>
                  Start your journey with us to explore thrilling fantasy
                  sports, compete, and win exciting rewards. Join now and enjoy!
                </p>
              </div>
            </div>
          </div>

          <div className="start_withsub">
            {journeyData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="start_withbox"
                  // style={{ animationDelay: `${index + 0.1}s` }}
                >
                  <div className="start_withicon">
                    <Icon name={item?.icon} className="img-fluid" />
                  </div>
                  <div>
                    <h5>{item?.title}</h5>
                    <p>{item?.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <div className="container">
        <section id="about">
          <div className="row align-items-center maximum_profirsec">
            <div className="col-lg-5 ">
              <div className="aboutus_img">
                <Icon name="aboutus" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-7 ">
              <div className="aboutus_text">
                <h4>
                  Custom Courier Solutions for Your Business, keep your business
                  safe.
                </h4>
                <p>
                  We represents the basic services for handling deliveries in
                  express and cargo mode in India. There are two services
                  offered under this
                </p>
                <p>
                  (1) Domestic Express Services for delivering documents and
                  small parcels.
                </p>
                <p>
                  (2) Domestic Cargo Services for delivering heavier
                  consignments that require special arrangements such as bigger
                  vehicles, dedicated manpower and material handling equipment.
                  It ensures priority deliveries at optimal costs, in both air
                  and surface modes.
                </p>
                <NavLink to={"/about-us"} className="primarybtn">
                  Read More
                </NavLink>
              </div>
            </div>
          </div>
        </section>
        <section id="whychoose" className="comman_space">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  Why Choose <span>us ?</span>
                </h4>
                <p>
                  Reliable, fast delivery with real-time tracking and secure
                  handling.
                </p>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-8 order-2 order-lg-1">
              {whyChooseData.map((item, index) => (
                <div className="why_choosebox">
                  <div>
                    <div className="whychoose_icon">
                      <Icon name={item?.icon} className="img-fluid" />
                    </div>
                  </div>
                  <div>
                    <h5>{item?.title}</h5>
                    <p>{item?.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-4 order-1 order-lg-2 text-lg-center text-start">
              <Icon name="whyChoose" className="img-fluid whychoosicon" />
            </div>
          </div>
        </section>

        <section className="comman_space pt-0">
          <div className="row justify-content-center my-2 ">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  FAQ’s About <span>Zippyglide.com </span>
                </h4>
                <p>
                  Have questions? Our FAQ section provides essential answers to
                  help you get started and enjoy our platform easily.
                </p>
              </div>
            </div>
          </div>
          <div
            className="accordion accordion-flush selfaccord"
            id="accordionFlushExample"
          >
            {accordionFullData?.map((item, index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id={item.id}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${index}1`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse${index}1`}
                  >
                    {item.title}
                  </button>
                </h2>
                <div
                  id={`flush-collapse${index}1`}
                  className="accordion-collapse collapse"
                  aria-labelledby={item.id}
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">{item.content}</div>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* <section id="contact" className="comman_space">
          <div className="contact_main">
            <div className="d-md-flex justify-content-between align-items-center d-block">
              <div className="contact_text">
                <h4 className="mb-0">Contact Us</h4>
                <p>Get in touch with our team to learn more about</p>
                <Button
                  className="primarybtn"
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                >
                  Connect With Us
                </Button>
              </div>
              <div className="contact_icon">
                <Icon name="contactushome" className="img-fluid" />
              </div>
            </div>
          </div>
        </section> */}

        {/* <div className="categories_swiper">
          <Swiper
            centeredSlides={false}
            spaceBetween={20}
            speed={900}
            parallax
            rewind
            loop
            effect="slide"
            freeMode
            grabCursor={true}
            watchSlidesProgress
            slidesPerView={3}
            breakpoints={{
              1200: { slidesPerView: 4 },
              992: { slidesPerView: 3 },
              768: { slidesPerView: 3 },
              576: { slidesPerView: 2 },
              0: { slidesPerView: 1.5 },
            }}
            autoplay={{
              delay: 19000,
            }}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
          >
            {clientReview?.map((data, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="category_card ">
                    <Icon name="earn1" className="" />
                    <div className="play_cardtext">
                      <h4>{data?.title}</h4>
                      <p>{data?.productNo}</p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div> */}
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default Home;
