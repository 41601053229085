import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "../../ui";

const Detail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(location.state?.data || null);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = () => {
    // navigate("/");
    setData(null);
    // return;
  };

  useEffect(() => {
    if (!data) {
      navigate("/");
      setData(null);
      return;
    }
  }, [data, navigate, location.pathname]);

  const details = [
    { label: "Sender’s Name", value: data?.data?.company_name },
    { label: "From", value: data?.data?.f_address },
    { label: "To", value: data?.data?.to_address },
    // {
    //   label: "Updated At",
    //   value: new Date(data?.data?.txn_date).toLocaleDateString('en-GB', {
    //     day: '2-digit',
    //     month: '2-digit',
    //     year: 'numeric',
    //   })
    // },
    //{ label: "Created At", value: data?.data?.created_at },
  ];

  return (
    <div className="container">
      <div className="comman_space mt-5">
        <div className="row justify-content-center my-2 pt-5">
          <div className="col-lg-6 col-md-10 text-center">
            <div className="commantext">
              <h4>
                Your <span>Shipment</span> Details
              </h4>
              <p>
                Effortlessly monitor your shipment's journey in real-time and
                stay updated on its status every step of the way.
              </p>
            </div>
          </div>
        </div>
        <div className="py-2 detailmain">
          {data?.status === true ? (
            <div>
              <div className="detailcard my-3">
                <h6 className="detail_heading">Delivery Detail</h6>
                <div className="tablemain">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Tracking ID</th>
                        <th>Dispatch date</th>
                        <th>Delivery Date</th>

                        {/* <th>Number</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{data?.data?.cust_name}</td>
                        <td>{data?.data?.tracking_id}</td>
                        <td>
                          {new Date(data?.data?.recive_date).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </td>
                        <td>
                          {new Date(data?.data?.deli_date).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </td>
                        {/* <td>{data?.data?.delivery_person_contact_no}</td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="detailcard">
                <h6 className="detail_heading">Other Detail</h6>
                <div className="order_main">
                  {details.map((detail, index) => (
                    <div key={index} className="order_sub">
                      <h6>{detail.label}</h6>
                      <p>{detail.value}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center notFound_sec">
              <Icon name="notfound" className="img-fluid" />
              <div className="message_size py-4">Order Details not found!</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Detail;
